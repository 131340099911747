import {
    CFormInput,
    CRow,
    CCol,
    CCard,
    CFormTextarea,
    CFormSelect,
    CFormLabel,
    CButton,
    CFormCheck,
    CLink,
    CAlert,
} from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import { useState } from 'react';
import './App.css';
import { clsx } from 'clsx';
import FamilyTable from './FamilyTable';
import Languages from './Languages';
import Jobs from './Jobs';
import { BACKEND_API_URL, pageToRedirect, personalData } from './api';
import Recs from './Recs';

const App = () => {
    const [error, setError] = useState(false);

    const [surnameRus, setSurnameRus] = useState('');
    const [surnameRusError, setSurnameRusError] = useState(false);

    const [surnameEng, setSurnameEng] = useState('');
    const [surnameEngError, setSurnameEngError] = useState(false);

    const [nameRus, setNameRus] = useState('');
    const [nameRusError, setNameRusError] = useState(false);

    const [nameEng, setNameEng] = useState('');
    const [nameEngError, setNameEngError] = useState(false);

    const [fatherName, setFatherName] = useState('');

    const [birthDate, setBirthDate] = useState('');
    const [birthDateError, setBirthDateError] = useState('');

    const [birthPlace, setBirthPlace] = useState('');
    const [birthPlaceError, setBirthPlaceError] = useState(false);

    const [ifChangeFio, setIfChangeFio] = useState('');

    const [citizenship, setCitizenship] = useState('');
    const [citizenshipError, setCitizenshipError] = useState(false);

    const [criminal, setCriminal] = useState(false);
    const [whyCriminal, setWhyCriminal] = useState('');
    const [whyCriminalError, setWhyCriminalError] = useState(false);

    const [buisness, setBuisness] = useState('');
    const [relativePhone, setRelativePhone] = useState('');
    const [war, setWar] = useState('');

    const [addressPasport, setAddressPasport] = useState('');
    const [address, setAddress] = useState('');
    const [addressPasportError, setAddressPasportError] = useState(false);
    const [addressError, setAddressError] = useState(false);

    const [strah, setStrah] = useState('');
    const [inn, setInn] = useState('');
    const [strahError, setStrahError] = useState(false);
    const [innError, setInnError] = useState(false);

    const [drive, setDrive] = useState('');
    const [car, setCar] = useState('');
    const [carNumber, setCarNumber] = useState('');

    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);

    const [family, setFamily] = useState([]);
    const [familyError, setFamilyError] = useState(false);

    const [languages, setLanguages] = useState([]);

    const [jobs, setJobs] = useState([]);
    const [jobsError, setJobsError] = useState(false);
    const [jobsError2, setJobsError2] = useState(false);

    const [document, setDocument] = useState('Паспорт');

    const [seria, setSeria] = useState('');
    const [nomer, setNomer] = useState('');
    const [kod, setKod] = useState('');
    const [seriaError, setSeriaError] = useState(false);
    const [nomerError, setNomerError] = useState(false);
    const [kodError, setKodError] = useState(false);

    const [dateDoc, setDateDoc] = useState('');

    const [docByWho, setDocByWho] = useState('');
    const [docByWhoError, setDocByWhoError] = useState(false);

    const [recs, setRecs] = useState([]);

    const [pers, setPers] = useState(false);

    const [response, setResponse] = useState('');

    const [confirm, setConfirm] = useState(false);

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const START_FIRST_NUMBERS = ['7', '8', '9'];

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    function getCurrentAge(date) {
        return ((new Date().getTime() - new Date(date)) / (24 * 3600 * 365.25 * 1000)) | 0;
    }

    const handleSend = () => {
        setError(false);

        setSurnameRusError(false);
        setSurnameEngError(false);
        setNameRusError(false);
        setNameEngError(false);
        setBirthPlaceError(false);
        setBirthDateError(false);
        setAddressError(false);
        setAddressPasportError(false);
        setSeriaError(false);
        setNomerError(false);
        setKodError(false);
        setStrahError(false);
        setInnError(false);
        setDocByWhoError(false);
        setPhoneError(false);
        setFamilyError(false);
        setJobsError(false);
        setCitizenshipError(false);
        setJobsError2(false);

        if (surnameRus.length < 3 || /[a-zA-Z]/.test(surnameRus)) {
            setSurnameRusError(true);
            setError(true);
        }

        if (surnameEng.length < 3 || /[а-яА-Я]/.test(surnameEng)) {
            setSurnameEngError(true);
            setError(true);
        }

        if (nameRus.length < 3 || /[a-zA-Z]/.test(nameRus)) {
            setNameRusError(true);
            setError(true);
        }

        if (nameEng.length < 3 || /[а-яА-Я]/.test(nameEng)) {
            setNameEngError(true);
            setError(true);
        }

        if (birthPlace.length < 3) {
            setBirthPlaceError(true);
            setError(true);
        }

        if (!birthDate) {
            setBirthDateError(true);
            setError(true);
        }

        if (citizenship.length < 3) {
            setCitizenshipError(true);
            setError(true);
        }

        if (criminal && whyCriminal.length < 3) {
            setWhyCriminalError(true);
            setError(true);
        }

        if (address.length < 10) {
            setAddressError(true);
            setError(true);
        }

        if (addressPasport.length < 10) {
            setAddressPasportError(true);
            setError(true);
        }

        if ((seria.length !== 4 && document === 'Паспорт') || seria.length === 0) {
            setSeriaError(true);
            setError(true);
        }

        if ((nomer.length !== 6 && document === 'Паспорт') || nomer.length === 0) {
            setNomerError(true);
            setError(true);
        }

        if ((kod.length !== 7 && document === 'Паспорт') || kod.length === 0) {
            setKodError(true);
            setError(true);
        }

        if (docByWho.length < 3) {
            setDocByWhoError(true);
            setError(true);
        }

        if (strah.length < 5 || !/[0-9]/.test(strah)) {
            setStrahError(true);
            setError(true);
        }

        if (inn.length !== 12) {
            setInnError(true);
            setError(true);
        }

        if (phone.length < 10) {
            setPhoneError(true);
            setError(true);
        }

        if (!jobs.length && getCurrentAge(birthDate) > 22) {
            setJobsError2(true);
            setError(true);
        }

        if (
            surnameRus.length < 3 ||
            /[a-zA-Z]/.test(surnameRus) ||
            surnameEng.length < 3 ||
            /[а-яА-Я]/.test(surnameEng) ||
            nameRus.length < 3 ||
            /[a-zA-Z]/.test(nameRus) ||
            nameEng.length < 3 ||
            /[а-яА-Я]/.test(nameEng) ||
            birthPlace.length < 3 ||
            citizenship.length < 3 ||
            (criminal && whyCriminal.length < 3) ||
            address.length < 10 ||
            addressPasport.length < 10 ||
            (seria.length !== 4 && document === 'Паспорт') ||
            seria.length === 0 ||
            (nomer.length !== 6 && document === 'Паспорт') ||
            nomer.length === 0 ||
            (kod.length !== 7 && document === 'Паспорт') ||
            kod.length === 0 ||
            docByWho.length < 3 ||
            strah.length < 5 ||
            !/[0-9]/.test(strah) ||
            strah.length < 5 ||
            !/[0-9]/.test(strah) ||
            inn.length !== 12 ||
            !/[0-9]/.test(inn) ||
            phone.length < 10 ||
            !birthDate ||
            (!jobs.length && getCurrentAge(birthDate) > 22)
        ) {
            return;
        }

        if (!confirm) {
            if (!family.length) {
                setFamilyError(true);
                setConfirm(true);
            }

            if (!jobs.length) {
                setJobsError(true);
                setConfirm(true);
            }
            if (!jobs.length || !family.length) return;
        } else {
            setJobsError(false);
            setFamilyError(false);
            setButtonDisabled(true);
        }

        const langs_data = languages.map((obj) => {
            return {
                lang_name: obj.lang_name,
                lang_level: obj.lang_level,
            };
        });

        const seniority_data = jobs.map((obj) => {
            return {
                started: obj.started,
                ended: obj.ended,
                job: obj.job,
                job_location: obj.job_location,
            };
        });

        const family_data = family.map((obj) => {
            return {
                relation: obj.rodstvo,
                full_name: obj.fio,
                birth_date_with_place: obj.birth,
                job: obj.job,
                location: obj.home,
            };
        });

        const recs_data = recs.map((obj) => {
            return {
                site: obj.site,
                lastPhone: obj.lastPhone,
                recman: obj.recman,
                recJob: obj.recJob,
            };
        });

        const data = {
            candidate_id: params.candidate_id,
            firstname: nameRus,
            middlename: surnameRus,
            lastname: fatherName,

            firstname_lat: nameEng,
            lastname_lat: surnameEng,

            birth_date: birthDate,
            birth_place: birthPlace,

            lastname_changed: ifChangeFio,
            citizen: citizenship,

            languages: langs_data,

            criminal_status: criminal,
            criminal_info: whyCriminal,

            seniority: seniority_data,

            organisations: buisness,

            family: family_data,

            near_relation_phone: relativePhone,
            military: war,
            address: addressPasport,
            address_real: address,
            document: {
                document_name: document,
                series: seria,
                number: nomer,
                date_of_issue: dateDoc,
                place_of_issue: docByWho,
                issuer_code: kod,
            },
            snils: strah,
            inn: inn,
            driver_license: drive,
            has_auto: car,
            auto_numbers: carNumber,
            phone_number: phone,
            recs: recs_data,
        };

        fetch(BACKEND_API_URL, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((result) => {
            setResponse(result.status);
            setTimeout(redirectPage, 2000);
        });
    };

    const redirectPage = () => {
        window.location.href = pageToRedirect;
    };

    const handleChangePhone = (e) => {
        const { value } = e.currentTarget;
        if (value.length > 18) return;
        let valueNumbers = value.replace(/\D/g, '');
        let newValue = '';
        if (!valueNumbers) return setPhone('');

        const startNum = valueNumbers[0];
        const isHasStartNumber = START_FIRST_NUMBERS.indexOf(startNum) > -1;

        if (isHasStartNumber) {
            if (startNum === '9') valueNumbers = '7' + valueNumbers;
            const firstSymbols = startNum === '8' ? '8' : '+7';
            newValue = firstSymbols;
            if (valueNumbers.length > 1) {
                newValue += '(' + valueNumbers.substring(1, 4);
            }
            if (valueNumbers.length >= 5) {
                newValue += ') ' + valueNumbers.substring(4, 7);
            }
            if (valueNumbers.length >= 8) {
                newValue += '-' + valueNumbers.substring(7, 9);
            }
            if (valueNumbers.length >= 10) {
                newValue += '-' + valueNumbers.substring(9, 11);
            }
        } else {
            newValue = '+' + valueNumbers.substring(0, 16);
        }

        setPhone(newValue);
    };

    const handlePhonePaste = (e) => {
        const { clipboardData, currentTarget } = e;
        const { value } = currentTarget;
        const valueNumbers = value.replace(/\D/g, '');

        if (clipboardData) {
            const clipboardText = clipboardData.getData('Text');
            if (/\D/g.test(clipboardText)) {
                return setPhone(valueNumbers);
            }
        }
    };

    const handleChangeStrah = (e) => {
        const new_value = e.target.value.replace(/[^\d]/g, '');
        setStrah(new_value);
    };

    const handleChangeInn = (e) => {
        const new_value = e.target.value.replace(/[^\d]/g, '');
        if (new_value.length > 12) return;
        setInn(new_value);
    };

    const handleSetNomer = (e) => {
        const new_value = e.target.value.replace(/[^\d]/g, '');
        if (new_value.length > 6) return;
        setNomer(new_value);
    };

    const handleSetSeria = (e) => {
        const new_value = e.target.value.replace(/[^\d]/g, '');
        if (new_value.length > 4) return;
        setSeria(new_value);
    };

    const handleSetDocument = (e) => {
        setDocument(e.target.value);
        setSeria('');
        setNomer('');
        setKod('');
    };

    const handleSetNomerZ = (e) => {
        setNomer(e.target.value);
    };

    const handleSetSeriaZ = (e) => {
        setSeria(e.target.value);
    };

    const handleSetKod = (e) => {
        let new_value = e.target.value.replace(/[^0-9-]/g, '');
        new_value = new_value.replace('--', '-');
        if (new_value.length === 3 && !new_value.includes('-')) {
            new_value = new_value + '-';
        }
        if (new_value.length === 1 && new_value.includes('-')) {
            new_value = '';
        }
        if (new_value.length > 7) return;
        setKod(new_value);
    };

    const handleSetKodZ = (e) => {
        let new_value = e.target.value.replace(/[^0-9-]/g, '');
        setKod(new_value);
    };

    const handleChangeBirthDate = (e) => {
        const curDate = new Date().getFullYear();
        let date = e.target.value.split('-');
        if (Number(date[0]) > curDate) {
            date[0] = String(curDate);
            const fullDate = date.join('-');
            setBirthDate(fullDate);
        } else {
            setBirthDate(e.target.value);
        }
        setBirthDateError(false);
    };

    const handleChangeDateDoc = (e) => {
        const curDate = new Date().getFullYear();
        let date = e.target.value.split('-');
        if (Number(date[0]) > curDate) {
            date[0] = String(curDate);
            const fullDate = date.join('-');
            setDateDoc(fullDate);
        } else {
            setDateDoc(e.target.value);
        }
    };

    return (
        <div className={clsx(window.screen.width > 767 && 'container py-5')}>
            <CCard>
                <CRow className="titlePadding">
                    <CCol className="text-center">
                        <h3 className="border-bottom pb-4">Анкета для внутренней проверки</h3>
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormInput
                            value={surnameRus}
                            label="Фамилия (рус)"
                            placeholder="Введите значение"
                            invalid={surnameRusError}
                            onChange={(e) => setSurnameRus(e.target.value)}
                        />
                    </CCol>
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormInput
                            value={surnameEng}
                            label="Фамилия (латиница)"
                            placeholder="Введите значение"
                            invalid={surnameEngError}
                            onChange={(e) => setSurnameEng(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormInput
                            value={nameRus}
                            label="Имя (рус)"
                            placeholder="Введите значение"
                            invalid={nameRusError}
                            onChange={(e) => setNameRus(e.target.value)}
                        />
                    </CCol>
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormInput
                            value={nameEng}
                            label="Имя (латиница)"
                            placeholder="Введите значение"
                            invalid={nameEngError}
                            onChange={(e) => setNameEng(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormInput
                            value={fatherName}
                            label="Отчество, если есть (рус)"
                            placeholder="Введите значение"
                            onChange={(e) => setFatherName(e.target.value)}
                        />
                    </CCol>
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormInput
                            type="date"
                            value={birthDate}
                            label="Дата рождения"
                            invalid={birthDateError}
                            onChange={handleChangeBirthDate}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormInput
                            value={birthPlace}
                            label="Место рождения"
                            placeholder="Введите значение"
                            invalid={birthPlaceError}
                            onChange={(e) => setBirthPlace(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormTextarea
                            value={ifChangeFio}
                            label="Если изменяли фамилию / имя / отчество, то укажите их (а также когда, где и по какой причине изменяли)"
                            placeholder="Введите значение"
                            onChange={(e) => setIfChangeFio(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormTextarea
                            value={citizenship}
                            label="Гражданство (если изменяли, то укажите, когда и по какой причине)"
                            placeholder="Введите значение"
                            invalid={citizenshipError}
                            onChange={(e) => setCitizenship(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormLabel>
                            <b>Уровень владения иностранным языком</b>
                        </CFormLabel>
                        <Languages languages={languages} setLanguages={setLanguages} />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormSelect label="Судимость" value={criminal} onChange={(e) => setCriminal(e.target.value)}>
                            <option value={true}>судим</option>
                            <option value={false}>не судим</option>
                        </CFormSelect>
                    </CCol>
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormInput
                            disabled={!criminal}
                            value={whyCriminal}
                            invalid={whyCriminalError}
                            label="Если Да, то когда и за что"
                            onChange={(e) => setWhyCriminal(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormLabel>
                            <b>
                                Выполняемая работа с начала трудовой деятельности (включая учёбу в высших и средних
                                специальных учебных заведениях, военную службу, работу по совместительству,
                                предпринимательскую деятельность и т.п.).
                            </b>
                            <br />
                            При заполнении данного пункта необходимо именовать учреждения, организации и предприятия
                            так, как они назывались в своё время, военную службу записывать с указанием должности и
                            номера воинской части.
                        </CFormLabel>
                        <Jobs jobs={jobs} setJobs={setJobs} />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormLabel>
                            <b>Кто Вас может порекомендовать</b>
                        </CFormLabel>
                        <Recs recs={recs} setRecs={setRecs} />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormInput
                            value={buisness}
                            label="В каких организациях Вы являетесь учредителем / акционером / директором или зарегистрированы в качестве ИП / самозанятого"
                            placeholder="Введите значение"
                            onChange={(e) => setBuisness(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol>
                        <CFormLabel>
                            <b>Ваши ближайшие родственники (мать, отец, жена, дети)</b>
                        </CFormLabel>
                        <FamilyTable family={family} setFamily={setFamily} />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormInput
                            value={relativePhone}
                            label="Телефон ближайшего родственника для экстренной связи"
                            placeholder="Введите значение"
                            onChange={(e) => setRelativePhone(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormTextarea
                            value={war}
                            label="Отношение к воинской обязанности и воинское звание"
                            placeholder="Введите значение"
                            onChange={(e) => setWar(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormSelect
                            value={document}
                            label="Документ, удостоверяющий личность"
                            placeholder="Введите значение"
                            onChange={handleSetDocument}
                        >
                            <option value="Паспорт">Паспорт РФ</option>
                            <option value="Загранпаспорт">Загранпаспорт</option>
                        </CFormSelect>
                    </CCol>
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormInput type="date" value={dateDoc} label="Дата выдачи" onChange={handleChangeDateDoc} />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3" xs={12} md={4}>
                        <CFormInput
                            value={seria}
                            invalid={seriaError}
                            label={document === 'Паспорт' ? 'Серия (состоит из первых 4 цифр)' : 'Серия'}
                            placeholder="Введите значение"
                            onChange={document === 'Паспорт' ? handleSetSeria : handleSetSeriaZ}
                        />
                    </CCol>
                    <CCol className="pb-3" xs={12} md={4}>
                        <CFormInput
                            value={nomer}
                            invalid={nomerError}
                            label={document === 'Паспорт' ? 'Номер (состоит из 6 цифр)' : 'Номер'}
                            placeholder="Введите значение"
                            onChange={document === 'Паспорт' ? handleSetNomer : handleSetNomerZ}
                        />
                    </CCol>
                    <CCol className="pb-3" xs={12} md={4}>
                        <CFormInput
                            value={kod}
                            invalid={kodError}
                            label="Код подразделения"
                            placeholder="Введите значение"
                            onChange={document === 'Паспорт' ? handleSetKod : handleSetKodZ}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormInput
                            value={docByWho}
                            invalid={docByWhoError}
                            label="Кем выдан"
                            placeholder="Введите значение"
                            onChange={(e) => setDocByWho(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormTextarea
                            value={addressPasport}
                            invalid={addressPasportError}
                            label="Адрес (с указанием индекса) по прописке"
                            placeholder="Введите значение"
                            onChange={(e) => setAddressPasport(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormTextarea
                            value={address}
                            invalid={addressError}
                            label="Адрес (с указанием индекса) фактический"
                            placeholder="Введите значение"
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormInput
                            value={strah}
                            invalid={strahError}
                            label="Страховое свидетельство Государственного Пенсионного страхования"
                            placeholder="Введите значение"
                            maxLength={11}
                            onChange={handleChangeStrah}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormInput
                            value={inn}
                            invalid={innError}
                            label="Свидетельство ИНН (физического лица)"
                            placeholder="Введите значение"
                            maxLength={12}
                            onChange={handleChangeInn}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormTextarea
                            value={drive}
                            label="Наличие водительского удостоверения, номер, кем и когда выдано"
                            placeholder="Введите значение"
                            onChange={(e) => setDrive(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormInput
                            value={car}
                            label="Наличие автотранспорта"
                            placeholder="Введите значение"
                            onChange={(e) => setCar(e.target.value)}
                        />
                    </CCol>
                    <CCol className="pb-3" xs={12} md={6}>
                        <CFormInput
                            value={carNumber}
                            label="Гос. номер"
                            placeholder="Введите значение"
                            onChange={(e) => setCarNumber(e.target.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormInput
                            value={phone}
                            invalid={phoneError}
                            label="Номер телефона (моб.)"
                            placeholder="Введите значение"
                            maxLength={18}
                            onChange={handleChangePhone}
                            onPaste={handlePhonePaste}
                        />
                    </CCol>
                </CRow>
                <CRow className="inputsPadding">
                    <CCol className="pb-3">
                        <CFormCheck checked={pers} onChange={() => setPers(!pers)} />
                        <span className="ms-2">Я принимаю </span>
                        <CLink target="_blank" href={personalData}>
                            условия согласия на обработку персональных данных.
                        </CLink>
                    </CCol>
                </CRow>
                {response === 200 && (
                    <CRow className="inputsPadding">
                        <CCol className="pb-3" xs={12}>
                            <CAlert color="success">Данные успешно отправлены!</CAlert>
                        </CCol>
                    </CRow>
                )}
                {(jobsError || jobsError2) && (
                    <CRow className="inputsPadding">
                        <CCol>
                            <small className="text-danger">- У Вас нет ни одной записи о трудовой деятельности</small>
                        </CCol>
                    </CRow>
                )}
                {familyError && (
                    <CRow className="inputsPadding">
                        <CCol>
                            <small className="text-danger">- У Вас нет ни одной записи о ближайшем родственнике</small>
                        </CCol>
                    </CRow>
                )}
                {(jobsError || familyError) && (
                    <CRow className="inputsPadding">
                        <CCol>
                            <small className="text-danger">
                                Нажмите "Отправить" повторно, если все равно хотите отправить
                            </small>
                        </CCol>
                    </CRow>
                )}
                {error && (
                    <CRow className="inputsPadding mb-3">
                        <CCol>
                            <small className="text-danger">
                                Пожалуйста, обязательно проверьте и дополните анкету необходимыми данными, в противном
                                случае ваша заявка может быть отклонена службой безопасности.
                            </small>
                        </CCol>
                    </CRow>
                )}
                <CRow className="d-flex justify-content-center">
                    <CCol className="pb-3 text-center" xs={12} md={3}>
                        <CButton
                            disabled={!pers || buttonDisabled}
                            color="success"
                            className="w-100"
                            onClick={handleSend}
                        >
                            Отправить
                        </CButton>
                    </CCol>
                </CRow>
            </CCard>
        </div>
    );
};

export default App;

import {
    CButton,
    CCol,
    CContainer,
    CFormInput,
    CRow,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CModalFooter,
} from '@coreui/react';
import { useState } from 'react';

const Recs = ({ recs, setRecs }) => {
    const [addModal, setAddModal] = useState(false);
    const [site, setSite] = useState('');
    const [lastPhone, setLastPhone] = useState('');
    const [recman, setRecman] = useState('');
    const [recJob, setRecJob] = useState('');

    const [id, setId] = useState(0);

    const [error, setError] = useState(false);
    const [lastPhoneError, setLastPhoneError] = useState(false);
    const [recmanError, setRecmanError] = useState(false);
    const [recJobError, setRecJobError] = useState(false);

    const [visible, setVisible] = useState(false);
    const [siteM, setSiteM] = useState('');
    const [lastPhoneM, setLastPhoneM] = useState('');
    const [recmanM, setRecmanM] = useState('');
    const [recJobM, setRecJobM] = useState('');

    const [idM, setIdM] = useState(0);

    const [errorM, setErrorM] = useState(false);
    const [lastPhoneErrorM, setLastPhoneErrorM] = useState(false);
    const [recmanErrorM, setRecmanErrorM] = useState(false);
    const [recJobErrorM, setRecJobErrorM] = useState(false);

    const handleSubmit = () => {
        setError(false);
        setLastPhoneError(false);
        setRecmanError(false);
        setRecJobError(false);

        if (!lastPhone) {
            setError(true);
            setLastPhoneError(true);
        }

        if (!recman) {
            setError(true);
            setRecmanError(true);
        }

        if (!recJob) {
            setError(true);
            setRecJobError(true);
        }

        const man = {
            id: id,
            site: site,
            lastPhone: lastPhone,
            recman: recman,
            recJob: recJob,
        };

        if (lastPhone && recman && recJob) {
            setRecs((recs) => [...recs, man]);
            setId(id + 1);
            setSite('');
            setLastPhone('');
            setRecman('');
            setRecJob('');
            setAddModal(false);
        } else {
            setError(true);
        }
    };

    const handleDelete = (id) => {
        setRecs(recs.filter((obj) => obj.id !== id));
    };

    const handleOpenModal = (obj) => {
        setVisible(true);
        setSiteM(obj.site);
        setLastPhoneM(obj.lastPhone);
        setRecmanM(obj.recman);
        setRecJobM(obj.recJob);
        setIdM(obj.id);
    };

    const handleSave = () => {
        setErrorM(false);
        setLastPhoneErrorM(false);
        setRecmanErrorM(false);
        setRecJobErrorM(false);

        if (!lastPhoneM) {
            setErrorM(true);
            setLastPhoneErrorM(true);
        }

        if (!recmanM) {
            setErrorM(true);
            setRecmanErrorM(true);
        }

        if (!recJobM) {
            setErrorM(true);
            setRecJobErrorM(true);
        }

        if (lastPhoneM && recmanM && recJobM) {
            setRecs(recs.filter((obj) => obj.id !== idM));
            const man = {
                id: idM,
                site: siteM,
                lastPhone: lastPhoneM,
                recman: recmanM,
                recJob: recJobM,
            };
            setRecs((recs) => [...recs, man]);
            setVisible(false);
        } else {
            setErrorM(true);
        }
    };

    return (
        <>
            <CModal alignment="center" visible={addModal} onClose={() => setAddModal(false)}>
                <CModalHeader onClose={() => setAddModal(false)}>
                    <CModalTitle>Добавление</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={site}
                                label="Сайт компании"
                                placeholder="Введите значение"
                                onChange={(e) => setSite(e.target.value)}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={lastPhone}
                                invalid={lastPhoneError}
                                label="Телефон"
                                placeholder="Введите значение"
                                onChange={(e) => setLastPhone(e.target.value)}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={recman}
                                invalid={recmanError}
                                label="ФИО"
                                placeholder="Введите значение"
                                onChange={(e) => setRecman(e.target.value)}
                            />
                        </CCol>
                        <CCol xs={12}>
                            <CFormInput
                                value={recJob}
                                invalid={recJobError}
                                label="Должность"
                                placeholder="Введите значение"
                                onChange={(e) => setRecJob(e.target.value)}
                            />
                        </CCol>
                        {error && (
                            <CCol xs={12}>
                                <small className="text-danger">Заполните все поля</small>
                            </CCol>
                        )}
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton onClick={handleSubmit}>Добавить</CButton>
                </CModalFooter>
            </CModal>

            {recs.map((obj) => {
                return (
                    <CContainer key={obj.id} fluid className="border-top py-1 px-0">
                        <CRow>
                            <CCol xs={12}>{obj.site}</CCol>
                            <CCol xs={12}>{obj.lastPhone}</CCol>
                            <CCol xs={12}>{obj.recman}</CCol>
                            <CCol xs={12}>{obj.recJob}</CCol>
                            <CCol xs={12}>
                                <CButton
                                    className="my-1"
                                    color="warning"
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleOpenModal(obj)}
                                >
                                    Редактировать
                                </CButton>
                                <CButton
                                    className="my-1 ms-2"
                                    color="danger"
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleDelete(obj.id)}
                                >
                                    Удалить
                                </CButton>
                            </CCol>
                        </CRow>
                    </CContainer>
                );
            })}

            <CContainer className="border-top border-bottom py-1">
                <CCol>
                    <CRow>
                        <CButton className="my-1" variant="outline" size="sm" onClick={() => setAddModal(true)}>
                            Добавить запись
                        </CButton>
                    </CRow>
                </CCol>
            </CContainer>

            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                <CModalHeader onClose={() => setVisible(false)}>
                    <CModalTitle>Редактирование рекомендателя</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={siteM}
                                label="Сайт последнего места работы"
                                placeholder="Введите значение"
                                onChange={(e) => setSiteM(e.target.value)}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={lastPhoneM}
                                invalid={lastPhoneErrorM}
                                label="Телефон последнего места работы"
                                placeholder="Введите значение"
                                onChange={(e) => setLastPhoneM(e.target.value)}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={recmanM}
                                invalid={recmanErrorM}
                                label="ФИО рекомендателя"
                                placeholder="Введите значение"
                                onChange={(e) => setRecmanM(e.target.value)}
                            />
                        </CCol>
                        <CCol xs={12}>
                            <CFormInput
                                value={recJobM}
                                invalid={recJobErrorM}
                                label="Должность рекомендателя"
                                placeholder="Введите значение"
                                onChange={(e) => setRecJobM(e.target.value)}
                            />
                        </CCol>
                        {errorM && (
                            <CCol xs={12}>
                                <small className="text-danger">Заполните все поля</small>
                            </CCol>
                        )}
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton onClick={handleSave}>Сохранить</CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};
export default Recs;

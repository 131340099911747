const { NODE_ENV } = process.env;
// апи
export const BACKEND_API_URL =
    NODE_ENV === 'development'
        ? 'https://hr.rrc.ru/integrations/sb_anketa'
        : 'https://hr.rrc.ru/integrations/sb_anketa';
// куда редирект после отправки
export const pageToRedirect = 'https://www.rrc.ru/thx/';
// персональные данные
export const personalData = 'https://telegra.ph/soglasie-05-10';

import {
    CButton,
    CCol,
    CContainer,
    CFormInput,
    CRow,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CModalFooter,
} from '@coreui/react';
import { useState } from 'react';

const FamilyTable = ({ family, setFamily }) => {
    const [rodstvo, setRodstvo] = useState('');
    const [fio, setFio] = useState('');
    const [birth, setBirth] = useState('');
    const [job, setJob] = useState('');
    const [home, setHome] = useState('');

    const [rodstvoError, setRodstvoError] = useState(false);
    const [fioError, setFioError] = useState(false);
    const [birthError, setBirthError] = useState(false);
    const [error, setError] = useState(false);

    const [id, setId] = useState(0);

    const [rodstvoM, setRodstvoM] = useState('');
    const [fioM, setFioM] = useState('');
    const [birthM, setBirthM] = useState('');
    const [errorM, setErrorM] = useState(false);

    const [rodstvoErrorM, setRodstvoErrorM] = useState(false);
    const [fioErrorM, setFioErrorM] = useState(false);
    const [birthErrorM, setBirthErrorM] = useState(false);

    const [jobM, setJobM] = useState('');
    const [homeM, setHomeM] = useState('');
    const [idM, setIdM] = useState(0);
    const [visible, setVisible] = useState(false);
    const [addModal, setAddModal] = useState(false);

    const handleSubmit = () => {
        setError(false);
        setRodstvoError(false);
        setBirthError(false);
        setFioError(false);

        if (!fio) {
            setError(true);
            setFioError(true);
        }

        if (!birth) {
            setError(true);
            setBirthError(true);
        }

        if (!rodstvo) {
            setError(true);
            setRodstvoError(true);
        }

        const man = {
            id: id,
            rodstvo: rodstvo,
            fio: fio,
            birth: birth,
            job: job,
            home: home,
        };
        if (fio && birth && rodstvo) {
            setFamily((family) => [...family, man]);
            setId(id + 1);
            setRodstvo('');
            setFio('');
            setBirth('');
            setJob('');
            setHome('');
            setAddModal(false);
        } else {
            setError(true);
        }
    };

    const handleDelete = (id) => {
        setFamily(family.filter((obj) => obj.id !== id));
    };

    const handleOpenModal = (obj) => {
        setVisible(true);
        setRodstvoM(obj.rodstvo);
        setFioM(obj.fio);
        setBirthM(obj.birth);
        setJobM(obj.job);
        setHomeM(obj.home);
        setIdM(obj.id);
    };

    const handleSave = () => {
        setErrorM(false);
        setRodstvoErrorM(false);
        setBirthErrorM(false);
        setFioErrorM(false);

        if (!fioM) {
            setErrorM(true);
            setFioErrorM(true);
        }

        if (!birthM) {
            setErrorM(true);
            setBirthErrorM(true);
        }

        if (!rodstvoM) {
            setErrorM(true);
            setRodstvoErrorM(true);
        }

        if (fioM && birthM && rodstvoM) {
            setFamily(family.filter((obj) => obj.id !== idM));
            const man = {
                id: idM,
                rodstvo: rodstvoM,
                fio: fioM,
                birth: birthM,
                job: jobM,
                home: homeM,
            };
            setFamily((family) => [...family, man]);
            setVisible(false);
        } else {
            setErrorM(true);
        }
    };

    return (
        <>
            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                <CModalHeader onClose={() => setVisible(false)}>
                    <CModalTitle>Редактирование</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol xs={12}>
                            <CFormInput
                                className="mb-2"
                                label="Степень родства"
                                placeholder="Введите значение"
                                value={rodstvoM}
                                invalid={rodstvoErrorM}
                                onChange={(e) => setRodstvoM(e.target.value)}
                            />
                        </CCol>
                        <CCol xs={12}>
                            <CFormInput
                                className="mb-2"
                                label="Фамилия, Имя, Отчество"
                                placeholder="Введите значение"
                                value={fioM}
                                invalid={fioErrorM}
                                onChange={(e) => setFioM(e.target.value)}
                            />
                        </CCol>
                        <CCol xs={12}>
                            <CFormInput
                                className="mb-2"
                                label="Год, место рождения"
                                placeholder="Введите значение"
                                value={birthM}
                                invalid={birthErrorM}
                                onChange={(e) => setBirthM(e.target.value)}
                            />
                        </CCol>
                        <CCol xs={12}>
                            <CFormInput
                                className="mb-2"
                                label="Место работы, должность"
                                placeholder="Введите значение"
                                value={jobM}
                                onChange={(e) => setJobM(e.target.value)}
                            />
                        </CCol>
                        <CCol xs={12}>
                            <CFormInput
                                className="mb-2"
                                label="Адрес места жительства"
                                placeholder="Введите значение"
                                value={homeM}
                                onChange={(e) => setHomeM(e.target.value)}
                            />
                        </CCol>
                        {errorM && (
                            <CCol xs={12}>
                                <small className="text-danger">Заполните все поля</small>
                            </CCol>
                        )}
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton onClick={handleSave}>Сохранить</CButton>
                </CModalFooter>
            </CModal>
            {family.map((obj) => {
                return (
                    <CContainer key={obj.id} fluid className="border-top py-1 px-0">
                        <CRow>
                            <CCol xs={12}>{obj.rodstvo}</CCol>
                            <CCol xs={12}>{obj.fio}</CCol>
                            <CCol xs={12}>{obj.birth}</CCol>
                            <CCol xs={12}>{obj.job}</CCol>
                            <CCol xs={12}>{obj.home}</CCol>
                            <CCol xs={12}>
                                <CButton
                                    className="my-1"
                                    color="warning"
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleOpenModal(obj)}
                                >
                                    Редактировать
                                </CButton>
                                <CButton
                                    className="my-1 ms-2"
                                    color="danger"
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleDelete(obj.id)}
                                >
                                    Удалить
                                </CButton>
                            </CCol>
                        </CRow>
                    </CContainer>
                );
            })}
            <CContainer className="border-top border-bottom py-1">
                <CCol>
                    <CRow>
                        <CButton className="my-1" variant="outline" size="sm" onClick={() => setAddModal(true)}>
                            Добавить запись
                        </CButton>
                    </CRow>
                </CCol>
            </CContainer>

            <CModal alignment="center" visible={addModal} onClose={() => setAddModal(false)}>
                <CModalHeader onClose={() => setAddModal(false)}>
                    <CModalTitle>Добавление</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow className="d-flex justify-content-center">
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={rodstvo}
                                invalid={rodstvoError}
                                label="Степень родства"
                                placeholder="Введите значение"
                                onChange={(e) => setRodstvo(e.target.value)}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={fio}
                                invalid={fioError}
                                label="Фамилия, Имя, Отчество"
                                placeholder="Введите значение"
                                onChange={(e) => setFio(e.target.value)}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={birth}
                                invalid={birthError}
                                label="Год, место рождения"
                                placeholder="Введите значение"
                                onChange={(e) => setBirth(e.target.value)}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={job}
                                label="Место работы, должность"
                                placeholder="Введите значение"
                                onChange={(e) => setJob(e.target.value)}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={home}
                                label="Адрес места жительства"
                                placeholder="Введите значение"
                                onChange={(e) => setHome(e.target.value)}
                            />
                        </CCol>
                        {error && (
                            <CCol xs={12}>
                                <small className="text-danger">Заполните все поля</small>
                            </CCol>
                        )}
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton onClick={handleSubmit}>Добавить</CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default FamilyTable;

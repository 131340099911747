import { useState } from 'react';
import {
    CButton,
    CCol,
    CContainer,
    CFormInput,
    CRow,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CModalFooter,
} from '@coreui/react';

const Jobs = ({ jobs, setJobs }) => {
    const [started, setStarted] = useState('');
    const [ended, setEnded] = useState('');
    const [job, setJob] = useState('');
    const [location, setLocation] = useState('');
    const [id, setId] = useState(0);
    const [visible, setVisible] = useState(false);
    const [startedM, setStartedM] = useState('');
    const [endedM, setEndedM] = useState('');
    const [jobM, setJobM] = useState('');
    const [locationM, setLocationM] = useState('');
    const [idM, setIdM] = useState(0);
    const [addModal, setAddModal] = useState(false);

    const [startedError, setStartedError] = useState(false);
    const [endedError, setEndedError] = useState(false);
    const [jobError, setJobError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [error, setError] = useState(false);

    const [startedErrorM, setStartedErrorM] = useState(false);
    const [endedErrorM, setEndedErrorM] = useState(false);
    const [jobErrorM, setJobErrorM] = useState(false);
    const [locationErrorM, setLocationErrorM] = useState(false);
    const [errorM, setErrorM] = useState(false);

    const handleAdd = () => {
        setStartedError(false);
        setEndedError(false);
        setJobError(false);
        setLocationError(false);
        setError(false);

        if (started === '') {
            setStartedError(true);
            setError(true);
        }

        if (ended === '') {
            setEndedError(true);
            setError(true);
        }

        if (job === '') {
            setJobError(true);
            setError(true);
        }

        if (location === '') {
            setLocationError(true);
            setError(true);
        }

        const cur_job = {
            id: id,
            started: started,
            ended: ended,
            job: job,
            job_location: location,
        };

        if (started && ended && job && location) {
            setJobs((jobs) => [...jobs, cur_job]);
            setStarted('');
            setEnded('');
            setJob('');
            setLocation('');
            setId(id + 1);
            setAddModal(false);
        }
    };

    const handleDelete = (id) => {
        setJobs(jobs.filter((obj) => obj.id !== id));
    };

    const handleOpenModal = (obj) => {
        setVisible(true);
        setStartedM(obj.started);
        setEndedM(obj.ended);
        setJobM(obj.job);
        setLocationM(obj.job_location);
        setIdM(obj.id);
    };

    const handleSave = () => {
        setErrorM(false);
        setStartedErrorM(false);
        setEndedErrorM(false);
        setJobErrorM(false);
        setLocationErrorM(false);

        if (startedM === '') {
            setStartedErrorM(true);
            setError(true);
        }

        if (endedM === '') {
            setEndedErrorM(true);
            setError(true);
        }

        if (jobM === '') {
            setJobErrorM(true);
            setError(true);
        }

        if (locationM === '') {
            setLocationErrorM(true);
            setError(true);
        }

        if (startedM && endedM && jobM && locationM) {
            setJobs(jobs.filter((obj) => obj.id !== idM));
            const cur_job = {
                id: idM,
                started: startedM,
                ended: endedM,
                job: jobM,
                job_location: locationM,
            };

            setJobs((jobs) => [...jobs, cur_job]);
            setVisible(false);
        } else {
            setErrorM(true);
        }
    };

    const handleSetStarted = (e) => {
        let new_value = e.target.value.replace(/[^0-9.]/g, '');
        new_value = new_value.replace('..', '.');
        if (new_value.length === 2 && !new_value.includes('.')) {
            new_value = new_value + '.';
        }
        if (new_value.length === 1 && new_value.includes('.')) {
            new_value = '';
        }
        if (new_value[1] === '.' && new_value[0] !== '0') {
            new_value = '0' + new_value;
        }
        if (Number(new_value.substring(0, 2)) > 12) {
            new_value = '12' + new_value.substring(2);
        }
        if (new_value.substring(0, 2) === '00') {
            new_value = '01' + new_value.substring(2);
        }
        if (new_value.length > 7) return;
        setStarted(new_value);
    };

    const handleSetEnded = (e) => {
        let new_value = e.target.value.replace(/[^0-9.]/g, '');
        new_value = new_value.replace('..', '.');
        if (new_value.length === 2 && !new_value.includes('.')) {
            new_value = new_value + '.';
        }
        if (new_value.length === 1 && new_value.includes('.')) {
            new_value = '';
        }
        if (new_value[1] === '.' && new_value[0] !== '0') {
            new_value = '0' + new_value;
        }
        if (Number(new_value.substring(0, 2)) > 12) {
            new_value = '12' + new_value.substring(2);
        }
        if (new_value.substring(0, 2) === '00') {
            new_value = '01' + new_value.substring(2);
        }
        if (new_value.length > 7) return;
        setEnded(new_value);
    };

    const handleSetStartedM = (e) => {
        let new_value = e.target.value.replace(/[^0-9.]/g, '');
        new_value = new_value.replace('..', '.');
        if (new_value.length === 2 && !new_value.includes('.')) {
            new_value = new_value + '.';
        }
        if (new_value.length === 1 && new_value.includes('.')) {
            new_value = '';
        }
        if (new_value[1] === '.' && new_value[0] !== '0') {
            new_value = '0' + new_value;
        }
        if (Number(new_value.substring(0, 2)) > 12) {
            new_value = '12' + new_value.substring(2);
        }
        if (new_value.substring(0, 2) === '00') {
            new_value = '01' + new_value.substring(2);
        }
        if (new_value.length > 7) return;
        setStartedM(new_value);
    };

    const handleSetEndedM = (e) => {
        let new_value = e.target.value.replace(/[^0-9.]/g, '');
        new_value = new_value.replace('..', '.');
        if (new_value.length === 2 && !new_value.includes('.')) {
            new_value = new_value + '.';
        }
        if (new_value.length === 1 && new_value.includes('.')) {
            new_value = '';
        }
        if (new_value[1] === '.' && new_value[0] !== '0') {
            new_value = '0' + new_value;
        }
        if (Number(new_value.substring(0, 2)) > 12) {
            new_value = '12' + new_value.substring(2);
        }
        if (new_value.substring(0, 2) === '00') {
            new_value = '01' + new_value.substring(2);
        }
        if (new_value.length > 7) return;
        setEndedM(new_value);
    };

    return (
        <>
            {jobs.map((obj) => {
                return (
                    <CContainer key={obj.id} fluid className="border-top py-1 px-0">
                        <CRow>
                            <CCol xs={12}>Начало: {obj.started}</CCol>
                            <CCol xs={12}>Окончание: {obj.ended}</CCol>
                            <CCol xs={12}>{obj.job}</CCol>
                            <CCol xs={12}>{obj.job_location}</CCol>
                            <CCol xs={12}>
                                <CButton
                                    className="my-1"
                                    color="warning"
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleOpenModal(obj)}
                                >
                                    Редактировать
                                </CButton>
                                <CButton
                                    className="my-1 ms-2"
                                    color="danger"
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleDelete(obj.id)}
                                >
                                    Удалить
                                </CButton>
                            </CCol>
                        </CRow>
                    </CContainer>
                );
            })}
            <CContainer className="border-top border-bottom py-1">
                <CCol>
                    <CRow>
                        <CButton className="my-1" variant="outline" size="sm" onClick={() => setAddModal(true)}>
                            Добавить запись
                        </CButton>
                    </CRow>
                </CCol>
            </CContainer>

            <CModal alignment="center" visible={addModal} onClose={() => setAddModal(false)}>
                <CModalHeader onClose={() => setAddModal(false)}>
                    <CModalTitle>Добавление</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow className="d-flex justify-content-center">
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={started}
                                invalid={startedError}
                                label="Месяц и год начала"
                                placeholder="Введите значение"
                                onChange={handleSetStarted}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={ended}
                                invalid={endedError}
                                label="Месяц и год окончания"
                                placeholder="Введите значение"
                                onChange={handleSetEnded}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={job}
                                invalid={jobError}
                                label="Должность с указанием учреждения, организации, предприятия (независимо от собственности и ведомственной принадлежности)
                        "
                                placeholder="Введите значение"
                                onChange={(e) => setJob(e.target.value)}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={location}
                                invalid={locationError}
                                label="Местонахождение учреждения, организации, предприятия"
                                placeholder="Введите значение"
                                onChange={(e) => setLocation(e.target.value)}
                            />
                        </CCol>
                        {error && (
                            <CCol xs={12}>
                                <small className="text-danger">Заполните все поля</small>
                            </CCol>
                        )}
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton onClick={handleAdd}>Добавить</CButton>
                </CModalFooter>
            </CModal>

            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                <CModalHeader onClose={() => setVisible(false)}>
                    <CModalTitle>Редактирование</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol xs={12}>
                            <CFormInput
                                className="mb-2"
                                label="Месяц и год начала"
                                placeholder="Введите значение"
                                value={startedM}
                                invalid={startedErrorM}
                                onChange={handleSetStartedM}
                            />
                        </CCol>
                        <CCol xs={12}>
                            <CFormInput
                                className="mb-2"
                                label="Месяц и год окончания"
                                placeholder="Введите значение"
                                value={endedM}
                                invalid={endedErrorM}
                                onChange={handleSetEndedM}
                            />
                        </CCol>
                        <CCol xs={12}>
                            <CFormInput
                                className="mb-2"
                                label="Должность с указанием учреждения, организации, предприятия (независимо от собственности и ведомственной принадлежности)"
                                placeholder="Введите значение"
                                value={jobM}
                                invalid={jobErrorM}
                                onChange={(e) => setJobM(e.target.value)}
                            />
                        </CCol>
                        <CCol xs={12}>
                            <CFormInput
                                className="mb-2"
                                label="Местонахождение учреждения, организации, предприятия"
                                placeholder="Введите значение"
                                value={locationM}
                                invalid={locationErrorM}
                                onChange={(e) => setLocationM(e.target.value)}
                            />
                        </CCol>
                        {errorM && (
                            <CCol xs={12}>
                                <small className="text-danger">Заполните все поля</small>
                            </CCol>
                        )}
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton onClick={handleSave}>Сохранить</CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default Jobs;

import {
    CButton,
    CCol,
    CContainer,
    CFormInput,
    CRow,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CModalFooter,
    CFormSelect,
} from '@coreui/react';
import { useState } from 'react';

const Languages = ({ languages, setLanguages }) => {
    const [lang, setLang] = useState('');
    const [level, setLevel] = useState('A1 - Начальный: Простые фразы и выражения');
    const [id, setId] = useState(0);
    const [addModal, setAddModal] = useState(false);

    const handleAdd = () => {
        const cur_lang = {
            id: id,
            lang_name: lang,
            lang_level: level,
        };
        if (lang && level) {
            setLanguages((languages) => [...languages, cur_lang]);
            setLang('');
            setLevel('');
            setId(id + 1);
            setAddModal(false);
        }
    };

    const handleDelete = (id) => {
        setLanguages(languages.filter((obj) => obj.id !== id));
    };

    return (
        <>
            {languages.map((obj) => {
                return (
                    <CContainer key={obj.id} fluid className="border-top py-1 px-0">
                        {obj.lang_name}: {obj.lang_level}{' '}
                        <CButton
                            className="my-1 ms-2"
                            color="danger"
                            variant="outline"
                            size="sm"
                            onClick={() => handleDelete(obj.id)}
                        >
                            Удалить
                        </CButton>
                    </CContainer>
                );
            })}

            <CContainer className="border-top border-bottom py-1">
                <CCol>
                    <CRow>
                        <CButton className="my-1" variant="outline" size="sm" onClick={() => setAddModal(true)}>
                            Добавить запись
                        </CButton>
                    </CRow>
                </CCol>
            </CContainer>

            <CModal alignment="center" visible={addModal} onClose={() => setAddModal(false)}>
                <CModalHeader onClose={() => setAddModal(false)}>
                    <CModalTitle>Добавление</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow className="d-flex justify-content-center">
                        <CCol className="pb-3" xs={12}>
                            <CFormInput
                                value={lang}
                                label="Язык"
                                placeholder="Введите значение"
                                onChange={(e) => setLang(e.target.value)}
                            />
                        </CCol>
                        <CCol className="pb-3" xs={12}>
                            <CFormSelect
                                value={level}
                                label="Степень владения"
                                placeholder="Введите значение"
                                onChange={(e) => setLevel(e.target.value)}
                            >
                                <option>A1 - Начальный: Простые фразы и выражения</option>
                                <option>A2 - Базовый: Общение на простые темы. </option>
                                <option>B1 - Средний: Простые разговоры, чтение, письмо. </option>
                                <option>B2 - Практический: Сложные разговоры, чтение и письмо. </option>
                                <option>C1 - Продвинутый: Свободное общение, узкоспециализированные темы. </option>
                                <option>C2 - Владение: Почти как носитель языка.</option>
                            </CFormSelect>
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton onClick={handleAdd}>Добавить</CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default Languages;
